#footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #01382E;
    color: #fff;
    padding: 1rem;
    .underline-text {
        border-bottom: 1px solid #fff;
    }
}
