#join-us{
    background-image: url("../../assets/images/join-us.png");
    min-height: 100vh;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: auto;
    position: relative;
    display: flex;
    justify-content: space-around;  
    .join-us-content-wrapper{
        margin-top : 15%;
        width: 100%;
        text-align: left;
        padding : 16px;
        .join-us-text-and-buttons-container{
           margin-left: 10%;
        }
    } 
}
@media screen and (min-width:550px) and (max-width:650px) {
    #join-us{
        .join-us-content-wrapper{
            margin-top: 5%;
            .join-us-text-and-buttons-container{
                margin-left : 2%
            }

            .leaderboard-main-wrapper{
                margin-left: 2%;
            }
        }
    }
}

@media screen and (max-width:549px) {
    #join-us{
        .join-us-content-wrapper{
            margin-top: 2%;
            .join-us-text-and-buttons-container{
                margin-left : 0%;
                // padding : 24px
            }

            .leaderboard-main-wrapper{
                margin-left: 0%;
                // padding : 24px;
                .app-statistics-container{
                    width: 100%;
                }
                .leaderboard-container{
                    width: 100%
                }
            }
        }
    }
}
