#main-container {
  position: relative;
  .main-container-image {
    width: 100%;
    object-fit: cover;
  }
  .text-container {
    position: absolute;
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 65%;
    text-align: center;
    line-height: normal;
    color: #ffffff;
    text-align: center;
  }
  .statistic-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1100px) {
  .text-container {
    top: 50% !important;
    width: 90% !important;
  }
}

@media screen and (min-width: 650px) and (max-width: 799px) {
  .text-container {
    top: 50% !important;
    width: 90% !important;
  }
  .welcome-text {
    font-size: 36px !important;
  }
  .MuiButton-root {
    height: 30px !important;
  }
}

@media screen and (max-width: 649px) {
  .text-container {
    top: 50% !important;
    width: 100% !important;
  }
  .welcome-text {
    font-size: 16px !important;
  }
  .sub-text {
    font-size: 8px !important;
  }
  .MuiButton-root {
    height: 30px !important;
  }
}
