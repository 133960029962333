.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.font-md {
  font-size: 16px;
}
.font-lg-1 {
  font-size: 30px;
}
.font-lg {
  font-size: 48px;
}
.m-1 {
  margin: 16px 0px !important;
}
.opacity-1 {
  opacity: 1 !important;
}
.text-white {
  color: #fff;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.d-none {
  display: none !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.items-center {
  align-items: center !important;
}
.justify-center {
  justify-content: center !important;
}
.ml-1 {
  margin-left: 1rem;
}
.mr-1{
  margin-right: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}

.mb-12 {
  margin-bottom: 12px !important;
}