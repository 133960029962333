#header {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #01382e;
  align-items: center;
  color: #fff;
  padding: 8px;
  .logo-container {
    margin-left: 16px;
  }
  .nav-options {
    display: flex;
    justify-content: space-around;
    // width: 40%;
    width: 500px;
    height: 40px;
    .nav-options-menu {
      // width: 33.33%;
      .arrow-icon {
        margin-left: 8px;
      }
      .visible {
        visibility: visible;
      }
      .hidden {
        visibility: hidden;
      }
    }
  }
  .hamburger-menu {
    display: none;
  }
}

@media screen and (max-width: 850px) {
  .nav-options {
    width: 100% !important;
  }
}

@media screen and (max-width: 549px) {
  .logo {
    height: 30px;
  }
  .nav-options {
    display: none !important;
  }
  .hamburger-menu {
    display: block !important;
  }
}
