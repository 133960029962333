#details {
  background-color: #ffff;
  padding: 40px;
  .detail-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 32px;
  }
}

@media screen and (max-width: 700px) {
  .detail-card-container {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 10px !important;
  }
}

@media screen and (max-width: 500px) {
  .detail-card-container {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10px !important;
  }
}

@media screen and (max-width: 400px) {
  .detail-card-container {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important ;
    gap: 10px !important;
  }
}
