#home{
    .swiper-container{
        padding : 32px 0px;
        background : #f8f8f8
    }
}

@media screen and (max-width : 500px) {
    #home{
        .swiper-container{
            padding : 0;;
        }
    }
}