.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
    display: none !important;
}
.custom-zoom-control {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .custom-zoom-control button {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    border-radius: 50%;
  }
  
  .custom-zoom-control button.zoom-in {
    width: 24px;
    height: 24px;
    background: url('../../assets/images/zoom-in.svg') center center no-repeat;
  }
  
  .custom-zoom-control button.zoom-out {
    width: 24px;
    height: 24px;
    background: url('../../assets/images/zoom-out.svg') center center no-repeat;
  }
  