#custom-label {
    background-color: #01382E;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    border-radius: 12px;
    width: 90%;
    min-width: 300px;
    margin: 8px;
    position: relative;
    height: 48px;
    .crown-tilted-image{
        position: relative;
        left : -30px;
        top : -30px
    }
  }
  