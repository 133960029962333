#statistic-card{
    background-color: #f8f8f8;
    border-radius: 4px;
    box-sizing: border-box;
    transition: 0.3s ease-in-out; 
    border : 1px solid #fff;
    overflow: hidden;
    .tree-image-container {
        padding : 8px;
        max-width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        .tree-image{
            height:120px;
            width: 120px;
        }
    }
    .planted-tree-data{
        padding : 8px
    }
    .zone {
        background-color: #01382E;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
        padding: 8px;
      }      
}

#statistic-card:hover{
 border : 1px solid #01382E;
 background: #fff !important;
}