#rank{
    .crown-image{
        position: absolute;
        top: -35%;
        left: 31%
    }
    .student-image-container {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        border: 3px solid #363F2C;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        .student-image{
            border-radius: 50%;
            object-fit: cover;
        }
      } 
    .rank-ellipse {
        position: absolute;
        background-color: #363F2C;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 30px;
        bottom: -11px;
    }        
}