.swiper-button-prev{
    margin-left: 5%;
}

.swiper-button-next{
    margin-right: 5%;
}

.swiper-button-prev::after{
    display: none;
}

.swiper-button-next::after{
    display: none;
}