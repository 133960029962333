#dialog-header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  background-color: #01382e;
  color: #fff;
  padding: 1rem;
  .options {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .options-content {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}

@media screen and (max-width: 1000px) {
  .options {
    justify-content: space-around !important;
  }
}

@media screen and (max-width: 650px) {
  .custom-dialog-header {
    display: block !important;
  }
  .options {
    display: block !important;
  }
  .options-content {
    width: auto !important;
  }
}
